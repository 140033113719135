<template>
    <div class="myCount">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span class="tab_box" v-if="btnP.btnCount">
                        <i class="iconfont icon-qunfengyijiesuan"></i>
                        我的结算单</span
                    >
                    <!-- <span v-if="btnP.depSearch">部门</span>
                    <el-select
                        v-if="btnP.depSearch"
                        v-model="searchVal.departmentId"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择"
                        @change="changeDepartmentId()"
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-if="btnP.userSearch">成员</span>
                    <el-select
                        v-if="btnP.userSearch"
                        v-model="searchVal.adminId"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="请选择"
                        @change="getTableData(isStsts)"
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                    <Department
                        ref="department"
                        :departmentWidth="170"
                        :adminWidth="170"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                </p>
                <p></p>
            </div>
        </div>
        <div class="table-title">
            <div class="status_btn">
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(1)"
                    :class="isStsts == 1 ? 'isStatusColor' : ''"
                    >全部</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(2)"
                    :class="isStsts == 2 ? 'isStatusColor' : ''"
                >
                    待确认
                </span>
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(3)"
                    :class="isStsts == 3 ? 'isStatusColor' : ''"
                    >已确认</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(4)"
                    :class="isStsts == 4 ? 'isStatusColor' : ''"
                    >已驳回</span
                >
                <span
                    @click="(pagesize = 20), (currentPage = 1), getTableData(5)"
                    :class="isStsts == 5 ? 'isStatusColor' : ''"
                    >已过期</span
                >
            </div>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column label="时间" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.createTime
                            ? scope.row.createTime.slice(0, 16)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column prop="contactPhone" width="190" label="结算周期">
                <template slot-scope="scope">
                    <span>
                        {{
                            scope.row.billStartTime
                                ? scope.row.billStartTime.slice(0, 10)
                                : '- -'
                        }}
                    </span>
                    ~
                    <span>{{
                        scope.row.billEndTime
                            ? scope.row.billEndTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template> </el-table-column
            ><el-table-column min-width="20"> </el-table-column>
            <el-table-column label="账单编号" width="120" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.billNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="所属部门" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-tooltip placement="top"
                        >·
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in scope.row.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(scope.row) }}
                        </em>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="成员" width="55" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.adminName || '- -' }}</span></template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="合同金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.contractTotalAmount || '- -'
                    }}</span></template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="回款金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.actualTotalAmount || '- -'
                    }}</span></template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="业绩" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.achievementTotalAmount || '- -'
                    }}</span></template
                >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="状态" width="60" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待发送'
                            : scope.row.status == 2
                            ? '待确认'
                            : scope.row.status == 3
                            ? '已确认'
                            : scope.row.status == 4
                            ? '已驳回'
                            : scope.row.status == 5
                            ? '已过期'
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                prop="createTime"
                label="操作"
                width="100"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="btnP.detail"
                        @click="onDetail(scope.row)"
                        >明细</el-button
                    ></template
                >
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>
<script>
import { billList } from '@/api/count/business';
import { roleList } from '@/api/user/clue';
import { departmentList } from '@/api/admin/framework/framework.js';
import Department from '../../../components/Department';
export default {
    data() {
        return {
            searchVal: {},
            btnP: {},
            dempData: [],
            userData: [],
            isPage: false,
            tableData: [{}],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isStsts: 1,
            pageData: {},
        };
    },
    components: {
        Department,
    },
    mounted() {
        if (sessionStorage.getItem('pageData')) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            this.searchVal = JSON.parse(
                sessionStorage.getItem('pageData')
            ).param;
            this.$refs.department.$data.departmentId =
                JSON.parse(sessionStorage.getItem('pageData')).param
                    .departmentId || undefined;
            this.$refs.department.$data.adminId =
                JSON.parse(sessionStorage.getItem('pageData')).param.adminId ||
                '';
            if (this.searchVal.departmentId) {
                this.$refs.department.getUserList(
                    JSON.parse(sessionStorage.getItem('pageData')).param
                        .departmentId
                );
            }
            if (this.searchVal.status) {
                this.isStsts = this.searchVal.status;
            }

            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
            if (this.$route.query.name) {
                this.searchVal.name = this.$route.query.name;
            }
        }

        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getTableData(this.isStsts);
        },
        getTableData(i) {
            this.isStsts = i;
            let data = {
                param: {},
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (i == 1) {
                data.param.statusList = [2, 3, 4, 5];
            } else {
                data.param.status = i;
            }
            if (!this.btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.btnP.userSearch) {
                data.param.adminId = this.searchVal.adminId;
            } else {
                if (!this.btnP.depSearch) {
                    data.param.adminId = sessionStorage.getItem('adminId');
                }
            }
            this.pageData = data;
            billList(data).then((res) => {
                console.log(res.data);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getTableData(this.isStsts);
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.dempData = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData(this.isStsts);
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.currentPage = 1;
            this.getTableData(this.isStsts);
        },
        onDetail(row) {
            this.$router.push({
                path: '/myCountDetail',
                query: {
                    id: row.id,
                    adminId: row.adminId,
                },
            });
            sessionStorage.setItem('pageData', JSON.stringify(this.pageData));
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
    },
};
</script>
<style lang="scss" scoped>
.myCount {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                display: flex;
                align-items: center;
                button {
                    background: #2370eb;
                    border-radius: 2px;

                    margin-right: 16px;
                }
            }
            .tab_box {
                display: flex;
                width: 100px;
                height: 32px;
                background: #edf1f3;
                box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                border-radius: 2px;
                font-size: 12px;
                font-weight: 500 !important;
                align-items: center;
                .iconfont {
                    font-size: 20px;
                    color: #8292b9;
                    box-shadow: 0px 2px 4px 0px rgba(237, 241, 243, 0.6);
                    border-radius: 50;
                    margin-left: 6px;
                    margin-right: 8px;
                }
            }
        }
    }
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.table-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    .status_btn {
        span {
            display: inline-block;
            border: 1px solid #f0f0f0;
            border-radius: 13px;
            padding: 4px 12px;
            font-size: 13px;
            color: #666666;
            margin-right: 12px;
            cursor: pointer;
            position: relative;
        }
        .isStatusColor {
            background: #e9f2ff;
            color: #2370eb;
            border-color: #e9f2ff;
        }
    }
}
</style>
